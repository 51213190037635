import { DEFAULT_HOME_ROUTE, PROFILE_MAX_AGE_SEC, PROFILE_STALE_WHILE_REVALIDATE_SEC } from 'config'
import { ProfileFeed } from 'containers/ProfileFeed'
import { ViewProfileResponse } from 'graphql/queries/viewProfile'
import { WithPublicPageContext, withPublicPage } from 'hocs/withPublicPage'
import { NextPageProps } from 'interfaces/index'
import { gqlRequest } from 'lib/gql/gqlRequest'
import { getPageTitle } from 'lib/nextjs/getPageTitle'
import { nextContextRedirect } from 'lib/nextjs/nextContextRedirect'
import { rootStore } from 'models/RootStore'
import { Layout } from 'shared/Layout'
import { gql } from 'urql'

const PostPage: NextPageProps = ({ meta }) => {
  return (
    <Layout
      metaTitle={meta?.title}
      metaDesc={meta?.desc}
      metaImg={meta?.img}
      canonicalUrl={meta?.canonical}
    >
      <ProfileFeed />
    </Layout>
  )
}

PostPage.getInitialProps = async (ctx: WithPublicPageContext) => {
  const username = typeof ctx.query.username === 'string' ? ctx.query.username : undefined

  if (!username) {
    return nextContextRedirect(ctx, DEFAULT_HOME_ROUTE)
  }

  let profile = rootStore?.feed.selectedProfile?.profile

  if (!profile) {
    const res = await gqlRequest<ViewProfileResponse>(
      gql`
        query ViewProfile($username: String) {
          viewProfile(username: $username) {
            bio
            avatar {
              url
            }
          }
        }
      `,
      { username }
    )
    profile = res.data?.viewProfile
  }

  ctx.res?.setHeader(
    'Cache-Control',
    `public, s-maxage=${PROFILE_MAX_AGE_SEC}, stale-while-revalidate=${PROFILE_STALE_WHILE_REVALIDATE_SEC}`
  )

  return {
    meta: {
      title: getPageTitle(username),
      desc: profile?.bio,
      img: profile?.avatar?.url
    },
    store: {
      feed: {
        selectedProfileUsername: ctx.query.username
      }
    }
  }
}

export default withPublicPage(PostPage)
