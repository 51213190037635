import { ComponentProps } from '@stitches/react'
import { PostVideoModal } from 'containers/PostVideoModal'
import { useIsDesktopSize } from 'hooks/useIsDesktopSize'
import { useStore } from 'lib/store'
import { NSFW_EVENT } from 'lib/tracking/types'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import dynamic from 'next/dynamic'
import { Flex } from 'primitives/Flex'
import { useEffect } from 'react'
import { FeedsMenu } from 'shared/FeedsMenu'
import { DesktopFeedRightColumn } from 'shared/DesktopFeedRightColumn'
import { FeedLinksMenu } from 'shared/FeedLinksMenu'
import { CenterColumn, LayoutColumns, LeftColumn } from 'shared/Layout'
import { ProfileCard } from 'shared/ProfileCard'
import { CenterColContentProps } from './components/CenterColContent'
import { LeftSideNav } from 'shared/LeftSideNav'

const CenterColContent = dynamic<CenterColContentProps>(
  () => import('./components/CenterColContent').then((mod) => mod.CenterColContent),
  { ssr: false }
)
export const getPostPositionIndexForGrid = (rowIdx: number, colIdx: number) => rowIdx * 3 + colIdx

type Props = ComponentProps<typeof Flex>

export const ProfileFeed: React.FC<Props> = observer(({ css }) => {
  const store = useStore()
  const { profile, error } = store.feed.selectedProfile || {}

  const isDesktopSize = useIsDesktopSize(!store.ui.isMobile)

  useEffect(() => {
    if (profile) {
      store.analytics.track(NSFW_EVENT.VIEW_PROFILE_PAGE, {
        profileId: profile.profileId,
        username: profile.username
      })
    }
  }, [store.analytics, profile])

  return (
    <LayoutColumns css={css}>
      <LeftColumn>
        <LeftSideNav />
      </LeftColumn>
      <CenterColumn noGap={!isDesktopSize}>
        <CenterColContent profile={profile} error={error} isDesktopSize={isDesktopSize} />
      </CenterColumn>
      {isDesktopSize && (
        <DesktopFeedRightColumn>
          {profile && <ProfileCard profile={profile} />}
          {/* <SuggestionsMenu /> */}
        </DesktopFeedRightColumn>
      )}
      <PostVideoModal
        onClose={() => store.ui.setVideoModalAsset()}
        asset={store.ui?.videoModalAsset}
        isMobile={store.ui.isMobile}
      />
    </LayoutColumns>
  )
})
